import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/Home",
    name: "Home",
    component: Home,
    redirect: "/BannerManagement",
    meta: {
      isShow: false
    },
    children: [
      {
        path: "/BannerManagement",
        name: "BannerManagement",
        meta: {
          isShow: true,
          title: "广告管理",
          icon: "el-icon-document"
        },
        component: () => import("../views/BannerManagement.vue")
      },
      {
        path: "/ApplicationProject",
        name: "ApplicationProject",
        meta: {
          isShow: false,
          title: "报名详情",
          icon: "el-icon-document"
        },
        component: () => import("../views/ApplicationProject.vue")
      },
      {
        path: "/OldWithNew",
        name: "OldWithNew",
        meta: {
          isShow: false,
          title: "老带新列表",
          icon: "el-icon-document"
        },
        component: () => import("../views/OldWithNew.vue")
      },
      {
        path: "/UserCoupons",
        name: "UserCoupons",
        meta: {
          isShow: false,
          title: "领券详情",
          icon: "el-icon-document"
        },
        component: () => import("../views/UserCoupons.vue")
      },
      {
        path: "/RecommendFriend",
        name: "RecommendFriend",
        meta: {
          isShow: false,
          title: "推荐好友详情",
          icon: "el-icon-document"
        },
        component: () => import("../views/RecommendFriend.vue")
      },
      {
        path: "/ShopBanner",
        name: "ShopBanner",
        meta: {
          isShow: true,
          title: "门店广告",
          icon: "el-icon-office-building"
        },
        component: () => import("../views/BannerShop.vue")
      },
      {
        path: "/CityBanner",
        name: "CityBanner",
        meta: {
          isShow: true,
          title: "城市广告",
          icon: "el-icon-school"
        },
        component: () => import("../views/BannerCity.vue")
      },
      {
        path: "/BannerDescImg",
        name: "BannerDescImg",
        meta: {
          isShow: false,
          title: "广告详图",
          icon: "el-icon-money"
        },
        component: () => import("../views/BannerDescImg.vue")
      },
      {
        path: "/Shop",
        name: "Shop",
        meta: {
          isShow: true,
          title: "门店管理",
          icon: "el-icon-s-shop"
        },
        component: () => import("../views/Shop.vue")
      },
      {
        path: "/transpond",
        name: "transpond",
        meta: {
          isShow: true,
          title: "转发配置",
          icon: "el-icon-s-promotion"
        },
        component: () => import("../views/transpond/index.vue")
      },
      {
        path: "/Order",
        name: "Order",
        meta: {
          isShow: true,
          title: "订单查询",
          icon: "el-icon-notebook-1"
        },
        component: () => import("../views/Order.vue")
      },
      {
        path: "/Goods",
        name: "Goods",
        meta: {
          isShow: true,
          title: "商品管理",
          icon: "el-icon-shopping-bag-1"
        },
        component: () => import("../views/Goods.vue")
      },
      {
        path: "/GoodsImg",
        name: "GoodsImg",
        meta: {
          isShow: false,
          title: "商品图片",
          icon: "el-icon-money"
        },
        component: () => import("../views/GoodsImg.vue")
      },
      {
        path: "/GoodsDescImg",
        name: "GoodsDescImg",
        meta: {
          isShow: false,
          title: "商品详图",
          icon: "el-icon-money"
        },
        component: () => import("../views/GoodsDescImg.vue")
      },
      {
        path: "/NumberCard",
        name: "NumberCard",
        meta: {
          isShow: true,
          title: "次卡管理",
          icon: "el-icon-bank-card"
        },
        component: () => import("../views/NumberCard.vue")
      },
      {
        path: "/Product",
        name: "Product",
        meta: {
          isShow: true,
          title: "产品管理",
          icon: "el-icon-box"
        },
        component: () => import("../views/Product.vue")
      },
      {
        path: "/ServiceItem",
        name: "ServiceItem",
        meta: {
          isShow: true,
          title: "服务管理",
          icon: "el-icon-news"
        },
        component: () => import("../views/ServiceItem.vue")
      },
      {
        path: "/Complaint",
        name: "Complaint",
        meta: {
          isShow: true,
          title: "客诉管理",
          icon: "el-icon-edit-outline"
        },
        component: () => import("../views/Complaint.vue")
      },
      {
        path: "/ReturnCard",
        name: "ReturnCard",
        meta: {
          isShow: true,
          title: "客户退卡",
          icon: "el-icon-bank-card"
        },
        component: () => import("../views/returnCard/index.vue")
      },
      {
        path: "/ReturnCardDetail",
        name: "ReturnCardDetail",
        meta: {
          isShow: false,
          title: "客户退卡详情"
        },
        component: () => import("../views/returnCard/cardDetail.vue")
      },
      {
        path: "/Appointment",
        name: "Appointment",
        meta: {
          isShow: true,
          title: "预约申请",
          icon: "el-icon-s-order"
        },
        component: () => import("../views/appointment/index.vue")
      },
      {
        path: "/AppointmentDetail",
        name: "AppointmentDetail",
        meta: {
          isShow: false,
          title: "预约申请详情",
          icon: "el-icon-s-order"
        },
        component: () => import("../views/appointment/appointmentDetail.vue")
      },
      {
        path: "/consultation",
        name: "Consultation",
        meta: {
          isShow: true,
          title: "咨询管理",
          icon: "el-icon-service"
        },
        component: () => import("../views/customerConsultation/index")
      },
      {
        path: "/consultationType",
        name: "ConsultationType",
        meta: {
          isShow: false,
          title: "咨询类型管理"
        },
        component: () => import("../views/customerConsultation/ConsultationType.vue")
      },
      {
        path: "/Member",
        name: "Member",
        meta: {
          isShow: true,
          title: "会员管理",
          icon: "el-icon-user-solid"
        },
        component: () => import("../views/Member.vue")
      },
      {
        path: "/MemberDetail",
        name: "MemberDetail",
        meta: {
          isShow: false,
          title: "会员详情"
        },
        component: () => import("../views/memberDetail/index")
      },
      {
        path: "/ComplaintType",
        name: "ComplaintType",
        meta: {
          isShow: true,
          title: "投诉类型",
          icon: "el-icon-s-opportunity"
        },
        component: () => import("../views/ComplaintType.vue")
      },
      {
        path: "/City",
        name: "City",
        meta: {
          isShow: true,
          title: "城市管理",
          icon: "el-icon-basketball"
        },
        component: () => import("../views/City.vue")
      },
      {
        path: "/Category",
        name: "Category",
        meta: {
          isShow: true,
          title: "商品分类",
          icon: "el-icon-s-flag"
        },
        component: () => import("../views/Category.vue")
      },
      {
        path: "/BackUser",
        name: "BackUser",
        meta: {
          isShow: true,
          title: "用户管理",
          icon: "el-icon-user"
        },
        component: () => import("../views/BackUser.vue")
      },
      {
        path: "/ServicePhone",
        name: "ServicePhone",
        meta: {
          isShow: true,
          title: "客服电话",
          icon: "el-icon-phone-outline"
        },
        component: () => import("../views/ServicePhone.vue")
      },
      {
        path: "/EvaluationConfiguration",
        name: "EvaluationConfiguration",
        meta: {
          isShow: true,
          title: "评价配置",
          icon: "el-icon-edit-outline"
        },
        component: () => import("../views/EvaluationConfiguration.vue")
      },
      {
        path: "/NoteConfiguration",
        name: "NoteConfiguration",
        meta: {
          isShow: true,
          title: "注意事项",
          icon: "el-icon-edit-outline"
        },
        component: () => import("../views/NoteConfiguration.vue")
      },
      {
        path: "/ChangePassword",
        name: "ChangePassword",
        meta: {
          isShow: true,
          title: "密码模板",
          icon: "el-icon-lock"
        },
        component: () => import("../views/ChangePassword")
      },
      {
        path: "/ReturnVisit",
        name: "ReturnVisit",
        meta: {
          isShow: true,
          title: "回访模板",
          icon: "el-icon-info"
        },
        component: () => import("../views/ReturnVisit")
      },
      {
        path: "/ScoreAndSwipeCard",
        name: "ScoreAndSwipeCard",
        meta: {
          isShow: true,
          title: "划卡模板",
          icon: "el-icon-minus"
        },
        component: () => import("../views/ScoreAndSwipeCard")
      },
      {
        path: "/ServiceEvaluation",
        name: "ServiceEvaluation",
        meta: {
          isShow: true,
          title: "评价列表",
          icon: "el-icon-user"
        },
        component: () =>
          import("../views/ServiceEvaluation.vue")
      },
      {
        path: "/Payment",
        name: "Payment",
        meta: {
          isShow: true,
          title: "支付配置",
          icon: "el-icon-open"
        },
        component: () => import("../views/Payment.vue")
      },
      {
        path: "/Pointsexchangetype",
        name: "Pointsexchangetype",
        meta: {
          isShow: false,
          title: "积分兑换分类",
          icon: "el-icon-s-order"
        },
        component: () => import( "../views/Pointsexchangetype.vue")
      },
      {
        path: "/PointsCoupons",
        name: "PointsCoupons",
        meta: {
          isShow: false,
          title: "积分及优惠券配置",
          icon: "el-icon-coin"
        },
        component: () => import("../views/PointsCoupons.vue")
      },
      {
        path: "/PointsMall",
        name: "PointsMall",
        meta: {
          isShow: true,
          title: "积分商城",
          icon: "el-icon-shopping-bag-1"
        },
        component: () => import("../views/integralShop/PointsMall.vue")
      },
      {
        path: "/vipequity",
        name: "vipequity",
        meta: {
          isShow: true,
          title: "权益列表",
          icon: "el-icon-s-order"
        },
        component: () => import("../views/vipequity.vue")
      },
      {
        path: "/vipmanage",
        name: "VipManage",
        meta: {
          isShow: true,
          title: "会员等级配置",
          icon: "el-icon-finished"
        },
        component: () => import("../views/VipManage.vue")
      },
      {
        path: "/vipblurb",
        name: "VipBlurb",
        meta: {
          isShow: false,
          title: "会员等级简介",
          icon: "el-icon-edit-outline"
        },
        component: () => import("../views/VipBlurb.vue")
      },
      {
        path: "/vipblurbtext",
        name: "vipblurbtext",
        meta: {
          isShow: false,
          title: "会员等级简介",
          icon: "el-icon-edit-outline"
        },
        component: () => import("../views/vipblurbtext.vue")
      },
      {
        path: "/LotteryRuleConfig",
        name: "LotteryRuleConfig",
        meta: {
          isShow: true,
          title: "九宫格抽奖规则配置",
          icon: "el-icon-present"
        },
        component: () => import("../views/LotteryRuleConfig.vue")
      },
      {
        path: "/PointsMallImg",
        name: "PointsMallImg",
        meta: {
          isShow: false,
          title: "商品详图",
          icon: "el-icon-money"
        },
        component: () => import("../views/integralShop/PointsMallImg.vue")
      },
      {
        path: "/ExchangeDetails",
        name: "ExchangeDetails",
        meta: {
          isShow: false,
          title: "兑换明细",
          icon: "el-icon-money"
        },
        component: () => import("../views/integralShop/ExchangeDetails.vue")
      },
      {
        path: "/TaskManage",
        name: "TaskManage",
        meta: {
          isShow: false,
          title: "任务配置",
          icon: "el-icon-s-operation"
        },
        component: () => import("../views/TaskManage.vue")
      },
      {
        path: "/TaskCollectionDetails",
        name: "TaskCollectionDetails",
        meta: {
          isShow: false,
          title: "任务领取明细",
          icon: "el-icon-operation"
        },
        component: () => import("../views/TaskCollectionDetails.vue")
      },
      {
        path: "/Drawrecords",
        name: "Drawrecords",
        meta: {
          isShow: false,
          title: "抽奖记录",
          icon: "el-icon-finished"
        },
        component: () => import("../views/Drawrecords.vue")
      },
      {
        path: "/TotalTaskManage",
        name: "TotalTaskManage",
        meta: {
          isShow: true,
          title: "任务配置",
          icon: "el-icon-finished"
        },
        component: () => import("../views/TotalTaskManage.vue")
      },
      {
        path: "/Prizeconfig",
        name: "Prizeconfig",
        meta: {
          isShow: false,
          title: "奖品配置表",
          icon: "el-icon-finished"
        },
        component: () => import("../views/Prizeconfig.vue")
      },
      {
        path: "/ops-membership",
        name: "ops-membership",
        meta: {
          isShow: true,
          title: "运维-会员关系查询",
          // icon: "el-icon-service"
        },
        component: () => import("../views/ops/membership/index")
      },
      {
        path: "/share-card-record",
        name: "share-card-record",
        meta: {
          isShow: true,
          title: "持卡分享记录"
        },
        component: () => import("../views/member/shareCardRecords/index")
      },
      {
        path: "/swipe-card-questionnaire",
        name: "swipe-card-questionnaire",
        meta: {
          isShow: true,
          title: "服务确认问卷"
        },
        component: () => import("../views/vipTemplate/swipeCardConfirmQuestionnaire/index")
      },
    ],
  },

  {
    path: "/",
    name: "Login",
    meta: {
      isShow: false,
      title: "生美服务平台",
    },
    component: Login,
    // component: () => import('../views/Login.vue')
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
